import {
  CUSTOM_TYPES as NewsCustomTypes,
  DYNAMIC_CUSTOM_TYPES as NewsDynamicCustomTypes,
} from '@/app/prismic/utils/pages/news'
import { CUSTOM_TYPES as PlatformCustomTypes } from '~/app/prismic/utils/pages/platform'
import { CUSTOM_TYPES as EuropeCustomTypes } from '~/app/prismic/utils/pages/europe'
import { CUSTOM_TYPES as USCustomTypes } from '~/app/prismic/utils/pages/united-states'
import { CUSTOM_TYPES as UKCustomTypes } from '~/app/prismic/utils/pages/united-kingdom'
import { CUSTOM_TYPES as AdditionalServicesCustomTypes } from '~/app/prismic/utils/pages/additional-services'
import { CUSTOM_TYPES as CultureCustomTypes } from '~/app/prismic/utils/pages/culture'
import { CUSTOM_TYPES as LegalCustomTypes } from '@/app/prismic/utils/pages/legal'
import { CUSTOM_TYPES as CareersCustomTypes } from '@/app/prismic/utils/pages/careers'
import { CUSTOM_TYPES as ContactCustomTypes } from '@/app/prismic/utils/pages/contact'
import {
  CUSTOM_TYPES as EngineeringCustomTypes,
  DYNAMIC_CUSTOM_TYPES as EngineeringDynamicCustomTypes,
} from '@/app/prismic/utils/pages/engineering'
import {
  CUSTOM_TYPES as BlogCustomTypes,
  DYNAMIC_CUSTOM_TYPES as BlogDynamicCustomTypes,
} from '@/app/prismic/utils/pages/blog'
import { CUSTOM_TYPES as AboutCustomTypes } from '@/app/prismic/utils/pages/about'
import { CUSTOM_TYPES as DebugCustomTypes } from '@/app/prismic/utils/pages/debug'
import { DYNAMIC_CUSTOM_TYPES as MarketingDynamicCampaignCustomTypes } from '@/app/prismic/utils/pages/campaigns'
import { DYNAMIC_CUSTOM_TYPES as MarketingDynamicLandingCustomTypes } from '@/app/prismic/utils/pages/landing'

export const CUSTOM_TYPES = {
  ...NewsCustomTypes,
  ...PlatformCustomTypes,
  ...EuropeCustomTypes,
  ...USCustomTypes,
  ...UKCustomTypes,
  ...AdditionalServicesCustomTypes,
  ...CultureCustomTypes,
  ...LegalCustomTypes,
  ...CareersCustomTypes,
  ...ContactCustomTypes,
  ...EngineeringCustomTypes,
  ...BlogCustomTypes,
  ...AboutCustomTypes,
  ...DebugCustomTypes,
  404: {
    url: '/404',
    title: '404',
  },
  home: {
    url: '/',
    title: 'Home',
  },
  feature_flags: {
    url: '/',
    title: 'Home',
  },
  menu: {
    url: '/',
    title: 'Home',
  },
}

const DYNAMIC_CUSTOM_TYPES = {
  ...NewsDynamicCustomTypes,
  ...EngineeringDynamicCustomTypes,
  ...BlogDynamicCustomTypes,
  ...MarketingDynamicCampaignCustomTypes,
  ...MarketingDynamicLandingCustomTypes,
}

export const defaultLanguage = 'en-gb'

export const getDocumentLocale = (doc) => {
  if (doc.lang === defaultLanguage) {
    return ''
  }

  return `/${doc.lang}`
}

export default (doc) => {
  const locale = getDocumentLocale(doc)

  if (doc.isBroken) {
    return `${locale}/404`
  }

  const customType = CUSTOM_TYPES[doc.type]
  const dynamicCustomType = DYNAMIC_CUSTOM_TYPES[doc.type]

  let documentUrl

  if (customType && customType.url) {
    documentUrl = customType.url
  } else if (dynamicCustomType && doc.uid) {
    documentUrl = dynamicCustomType.url(doc.uid)
  } else {
    // redirect to homepage by default
    documentUrl = ''
  }

  return doc.anchorId
    ? `${locale}${documentUrl}#${doc.anchorId}`
    : `${locale}${documentUrl}`
}
